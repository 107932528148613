import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby'
import Header from '../../components/Header';
// import Bio from '../components/Bio';
// import Posts from '../../components/Posts';
import SEO from '../../components/SEO';
import Posts from '../../components/Articles'


class Articles extends React.Component {
  render() {
    const { transition, data } = this.props;
    const { site, allJavascriptFrontmatter } = data;
    const articles = allJavascriptFrontmatter.edges;

    return (
      <div style={transition ? transition.style : { opacity: 0 }}>
        <Helmet
          title={`Articles | Raul Perte`}
        />
        <SEO postEdges={articles} />
        <Posts posts={articles} />
      </div>
    );
  }
}

Articles.propTypes = {
  route: PropTypes.object,
};

export default Articles;

export const query = graphql`
  query ArticlesQuery {
    site {
      siteMetadata {
        title
      }
    }
    allJavascriptFrontmatter(
      filter: { frontmatter: { isWork: { eq: false } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            path
            subtitle
            cover {
              childImageSharp {
                fluid(maxWidth: 1100, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    hero: file(relativePath: { eq: "hero-bw.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
